<template>
  <div>
    <span class="my-swipe-cur">{{ current + 1 }}/{{ datas.length }}</span>
    <van-swipe class="my-swipe" indicator-color="white" @change="onChange">
      <template v-for="(items, indexs) in datas">
        <van-swipe-item :key="indexs" v-if="items.type == 2">
          <video
            controls
            webkit-playsinline="true"
            playsinline="true"
            :poster="
              `${items.url}?spm=qipa250&x-oss-process=video/snapshot,t_7000,f_jpg,w_800,h_600,m_fast`
            "
            :src="items.url"
            class="video"
            muted
          ></video>
        </van-swipe-item>
        <van-swipe-item :key="indexs" v-else>
          <van-image class="img" :src="items.url" fit="contain" />
        </van-swipe-item>
      </template>
    </van-swipe>
  </div>
</template>

<script>
import { Swipe, SwipeItem, Image as VanImage } from "vant";
import "vant/lib/swipe/style";
import "vant/lib/swipe-item/style";
export default {
  props: {
    datas: {},
  },
  data() {
    return {
      current: 0,
    };
  },
  components: {
    VanImage,
    vanSwipe: Swipe,
    vanSwipeItem: SwipeItem,
  },
  methods: {
    onChange(index) {
      this.current = index;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.my-swipe {
  width: 100%;
  height: rem(710);
  margin: 0 auto rem(20);
  position: relative;
  .img {
    width: 100%;
    height: rem(710);
    border-radius: rem(16) rem(16) 0px 0px;
  }
}
.my-swipe-cur {
  position: absolute;
  width: rem(62);
  height: rem(32);
  background: rgba(0, 0, 0, 0.4);
  border-radius: rem(16);
  right: rem(20);
  top: rem(20);
  text-align: center;
  color: #fff;
  line-height: rem(32);
  z-index: 999;
}
/deep/ {
  .van-swipe__indicator {
    width: rem(6);
    height: rem(4);
    border-radius: rem(6);
    margin-right: rem(4);
  }
  .van-swipe__indicator--active {
    width: rem(16);
    height: rem(4);
    border-radius: rem(6);
  }
}

.video {
  width: 100%;
  height: 100%;
}
</style>
