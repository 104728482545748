<template>
  <div class="buyer">
    <ul class="b_ul">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <li class="b_li" v-for="(item, index) in buyer" :key="index">
          <oSwiper :datas="item.geval_image"></oSwiper>

          <div class="b_bottom">
            <p class="b_context">
              {{ item.geval_content }}
            </p>
            <!-- <span class="b_showContext">展开</span> -->
            <div class="b_info flex-container">
              <img
                class="b_headimg"
                src="https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=2913313147,2669161235&fm=26&gp=0.jpg"
              />
              <div class="flex1">
                <h2>{{ item.geval_frommembername }}</h2>
                <span
                  >{{ item.geval_addtime | time }} |
                  {{ item.geval_goodsname }}</span
                >
              </div>
              <div
                class="r_good flex-container"
                @click="setGoodAction(item.geval_id, index)"
              >
                <img
                  src="@image/goods/review_goods.png"
                  v-if="item.liked == 0"
                />
                <img src="@image/goods/review_goods_act.png" v-else />
                <i>{{ item.like }}</i>
              </div>
            </div>
          </div>
        </li>
      </van-list>
    </ul>
  </div>
</template>

<script>
import { Toast, Swipe, SwipeItem, List } from "vant";
import { post } from "@get/http";
import oSwiper from "./swiper";
import "vant/lib/toast/style";
import "vant/lib/swipe/style";
import "vant/lib/list/style";
import "vant/lib/swipe-item/style";
export default {
  data() {
    return {
      loading: false,
      finished: false,
      buyer: [],
      page: 1,
      goodTime: false,
      goodTimer: null,
    };
  },
  components: {
    vanSwipe: Swipe,
    vanSwipeItem: SwipeItem,
    vanList: List,
    oSwiper,
  },
  methods: {
    onChange(index, dom) {
      console.log(dom, index);
      this.buyer = index;
      // console.log(this.current);
    },
    setGoodAction(geval_id, index) {
      if (this.goodTime == true) {
        Toast("你点太快了");
        return false;
      }
      this.goodTime = true;
      // 点赞
      post(
        "/Memberevaluate/like",
        {
          data : {
          geval_id,
        },
        }

      ).then((res) => {
        Toast("成功");
        clearTimeout(this.goodTimer);
        this.goodTimer = setTimeout(() => {
          this.goodTime = false;
        }, 1500);
        console.log(index);
        this.buyer[index].liked = !this.buyer[index].liked;
        if (this.buyer[index].liked == true) {
          this.buyer[index].like++;
        } else {
          this.buyer[index].like--;
        }
        // this.$route.go(-1);
      });
    },
    onLoad() {
      post(
        "/Memberevaluate/show",
        {
          data : {
          commonid: this.$route.query.c_id, //商品commonid
          type: 0, //默认0)显示全部 1)只看有图 2)只看文字
          state: 2, //1)评论 2)买家秀 其他)10001错误报异常
          order: 0, //默认0)ID倒序 1)点赞倒序 2)点赞正序
          page: this.page,
          pageSize: 20,
        },
        }
     
      ).then((res) => {
        // 加载状态结束
        this.page++;
        this.loading = false;
        this.buyer.push(...res.data);
        if (res.data.length < 20) {
          this.finished = true;
        }
      });
    },
    autoVideo() {
      let elemets = document.querySelectorAll(".b_li");
      let videoElement = document.querySelectorAll(".video");
      let thisIndex = 0;
      function f() {
        videoElement[thisIndex].play();
      }
      let argHeight = [];
      let videoIndex = -1;
      if (elemets && elemets.length >= 2) {
        for (let index = 0; index < elemets.length; index++) {
          argHeight.push(
            (elemets[index].offsetTop + elemets[index].offsetHeight) / 2
          );
        }
        document.addEventListener("scroll", () => {
          for (let index = 0; index < argHeight.length; index++) {
            if (argHeight[index] > window.scrollY) {
              if (index == videoIndex) {
                break;
              }
              videoIndex = index;
              if (videoElement) {
                for (let i = 0; i < videoElement.length; i++) {
                  videoElement[i].pause();
                }
              }
              thisIndex = index;
              videoElement[thisIndex].play();
              // document.addEventListener("touchend", f, false);
              // document.removeEventListener("touchstart", f);
              break;
            }
          }
        });
      }
    },
  },
  mounted() {
    // autoVideo();
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.b_ul {
  padding-bottom: rem(50);
}
.b_li {
  width: rem(710);
  margin: 0 auto;
  background: #fff;
  border-radius: rem(16);
  margin-top: rem(30);
  position: relative;
}

.b_bottom {
  box-sizing: border-box;
  padding: 0 rem(24);
  padding-bottom: rem(40);
}
.b_context {
  // @include ell2(3);
  font-size: rem(26);
  color: #333;
  line-height: rem(36);
  margin-bottom: rem(10);
}
.b_showContext {
  color: #1a92e9;
  font-size: rem(26);
  line-height: rem(36);
}
.b_info {
  margin-top: rem(10);
  align-items: center;

  .b_headimg {
    border-radius: 50%;
    width: rem(68);
    height: rem(68);
    margin-right: rem(10);
  }
  h2 {
    font-size: rem(26);
    color: #333;
  }
  span {
    font-size: rem(22);
    color: #999;
  }
}

.r_good {
  text-align: right;
  justify-content: flex-end;

  img {
    width: reM(27);
    height: rem(27);
  }
  i {
    font-size: rem(26);
    color: #666;
    display: inline-block;
    margin-left: rem(6);
  }
}
</style>
